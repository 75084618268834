import type { FC, PropsWithChildren } from 'react';

import { warn } from '@hubcms/utils-monitoring';
import { AuthContext } from '@hubcms/data-access-auth';

export const NoAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AuthContext.Provider
      value={{
        user: null,
        isAuthenticated: false,
        login: returnUrl => {
          warn('Auth method set to none.');
          if (typeof window !== 'undefined') {
            window.location.href = returnUrl || '/';
          }
        },
        logout: returnUrl => {
          warn('Auth method set to none.');
          if (typeof window !== 'undefined') {
            window.location.href = returnUrl || '/';
          }
        },
        isLoading: false,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
